import type { Ref } from 'vue'

export function getOffset(element: HTMLElement, container: HTMLElement) {
  const offsets = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  }

  let currentElement: HTMLElement | null = element

  while (currentElement) {
    if (currentElement === container) {
      break
    }

    offsets.top += currentElement.offsetTop
    offsets.right += currentElement.offsetLeft + currentElement.clientWidth
    offsets.left += currentElement.offsetLeft
    offsets.bottom += currentElement.offsetTop + currentElement.clientHeight

    currentElement = currentElement.parentElement
  }

  return offsets
}

export default function useElementSize(
  element: HTMLElement | Ref<HTMLElement | null>,
  offsetContainer?: HTMLElement | Ref<HTMLElement | null>
) {
  const width = ref(0)
  const height = ref(0)

  const offsetTop = ref(0)
  const offsetRight = ref(0)
  const offsetLeft = ref(0)
  const offsetBottom = ref(0)

  const observer = new ResizeObserver(entries => {
    for (const entry of entries) {
      const { width: w, height: h } = entry.contentRect

      width.value = w
      height.value = h

      const targetElement = entry.target as HTMLElement

      const offsets = getOffset(targetElement, unref(offsetContainer) ?? document.body)

      offsetTop.value = offsets.top
      offsetRight.value = offsets.right
      offsetLeft.value = offsets.left
      offsetBottom.value = offsets.bottom
    }
  })

  watchEffect(() => {
    const el = unref(element)
    if (el) {
      width.value = el.clientWidth
      height.value = el.clientHeight

      observer.observe(el)
    }
  })

  onUnmounted(() => {
    observer.disconnect()
  })

  return { width, height, offsetTop, offsetRight, offsetLeft, offsetBottom }
}
