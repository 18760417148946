import type { Ref } from 'vue'

export default function useDragDrop(
  element: HTMLElement | Ref<HTMLElement | null>,
  isDraggable: Ref<boolean>,
  dragImage?: HTMLCanvasElement | HTMLImageElement | Ref<HTMLCanvasElement | null> | Ref<HTMLImageElement | null>,
  dragData?: Record<string, unknown> | Ref<Record<string, unknown>>,
  dragHandlers?: {
    onDragOver?: (event: DragEvent) => void
    onDragEnd?: (event: DragEvent) => void
  }
) {
  const isDragging = ref(false)

  const dragId = ref<string>('')

  const referenceElement = ref<HTMLElement | null>(null)

  const cleanUp = () => {
    if (dragImage && dragId.value) {
      const dragImageElement = document.querySelector(`#${dragId.value}`)
      if (dragImageElement) {
        document.body.removeChild(dragImageElement)
        dragId.value = ''
      }
    }

    document.body.style.cursor = 'auto'
  }

  const onDragStart = (event: DragEvent) => {
    if (!isDraggable.value) {
      event.preventDefault()
      return
    }

    isDragging.value = true

    document.body.style.cursor = 'grabbing'

    if (dragImage) {
      const dragImageElement = unref(dragImage)
      if (dragImageElement && referenceElement.value) {
        dragId.value = `drag-image-${Date.now()}`
        dragImageElement.id = dragId.value

        document.body.appendChild(dragImageElement)

        const { x, y } = referenceElement.value.getBoundingClientRect()

        event.dataTransfer?.setDragImage(dragImageElement, event.x - x, event.y - y)
      }
    }

    if (dragData) {
      const data = unref(dragData)

      const { x, y } = referenceElement.value?.getBoundingClientRect() ?? { x: 0, y: 0 }

      data.offsetX = event.x - x
      data.offsetY = event.y - y

      event.dataTransfer?.setData('text/plain', JSON.stringify(data))
    }
  }

  const onDragEnd = () => {
    isDragging.value = false

    cleanUp()
  }

  const setUpHandlers = () => {
    if (!referenceElement.value) return

    referenceElement.value.addEventListener('dragstart', onDragStart)
    referenceElement.value.addEventListener('dragend', onDragEnd)

    if (dragHandlers?.onDragOver) {
      referenceElement.value.addEventListener('dragover', dragHandlers.onDragOver)
    }
  }

  watchEffect(() => {
    const el = unref(element)

    if (el) {
      referenceElement.value = el
      setUpHandlers()
    }
  })

  onBeforeUnmount(() => {
    if (!referenceElement.value) return

    referenceElement.value.removeEventListener('dragstart', onDragStart)
    referenceElement.value.removeEventListener('dragend', onDragEnd)

    if (isDragging.value) {
      cleanUp()
    }

    if (dragHandlers?.onDragOver) {
      referenceElement.value.removeEventListener('dragover', dragHandlers.onDragOver)
    }
  })

  return {
    isDragging,
  }
}
